import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { useSession } from 'next-auth/react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import { Button } from 'components/button';
import { Select } from 'components/core/select';
import Accordeon from 'modules/accordeon/accordeon-module';
import IcnCarotUp from 'public/assets/icons/carot-up.svg';
import IcnCarotDown from 'public/assets/icons/carot-down.svg';
import { Icon } from 'components/icon/icon';
import {
	getServerPageFindNotesByRelation,
	getServerPageGetNewsArticleContactPerson,
	getServerPageGetPressReleaseContactPerson
} from 'api/graphql-helper';
import client from 'api/apollo-client';
import { NoteFieldsFragment } from 'api/graphql-types';
import styles from './note.module.scss';

interface ISize {
	id: number;
	label: string;
	height: string;
}

export const Note = (): React.JSX.Element => {
	const router = useRouter();
	const session = useSession();
	const intl = useIntl();
	const { contentType, contentTypeId } = useAppSelector((state) => state.settings);
	const { pathname } = router;
	const [notes, setNotes] = useState<NoteFieldsFragment[]>([]);
	const [contactPerson, setContactPerson] = useState<string>('');
	const [isOpen, setIsOpen] = useState(false);
	const [hasFetchedNotes, sethasFetchedNotes] = useState(false);
	const [hasFetchedContactPerson, setHasFetchedContactPerson] = useState(false);
	const toggleIcon = isOpen ? <IcnCarotDown /> : <IcnCarotUp />;

	const sizeOptions: ISize[] = [
		{ id: 0, label: '100%', height: '500px' },
		{ id: 1, label: '75%', height: '350px' },
		{ id: 2, label: '50%', height: '250px' }
	];

	const [selectedSize, setSelectedSize] = useState<number>(0);

	const toggleIsOpen = (): void => {
		setIsOpen(!isOpen);
	};

	const getContactPerson = async (): Promise<any> => {
		try {
			if (contentType === 'newsarticle' && contentTypeId) {
				const newsArticleContactPerson = await getServerPageGetNewsArticleContactPerson(
					{
						variables: {
							id: contentTypeId
						},
						context: {
							headers: {
								Authorization: `Bearer ${session.data?.accessToken}`
							}
						}
					},
					client
				);

				setContactPerson(newsArticleContactPerson.props.data.getNewsarticleContactPersonById?.contactPerson || '');
			}

			if (contentType === 'press-release' && contentTypeId) {
				const pressReleaseContactPerson = await getServerPageGetPressReleaseContactPerson(
					{
						variables: {
							id: contentTypeId
						},
						context: {
							headers: {
								Authorization: `Bearer ${session.data?.accessToken}`
							}
						}
					},
					client
				);

				setContactPerson(pressReleaseContactPerson.props.data.getPressReleaseContactPersonById?.contactPerson || '');
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
			setContactPerson('');
		} finally {
			setHasFetchedContactPerson(true);
		}
	};

	const getNotes = async (): Promise<any> => {
		try {
			const result = await getServerPageFindNotesByRelation(
				{
					variables: {
						contentTypeUid: `api::${contentType}.${contentType}`,
						contentTypeId: Number(contentTypeId)
					},
					context: {
						headers: {
							Authorization: `Bearer ${session.data?.accessToken}`
						}
					}
				},
				client
			);

			const fetchedNotes = result.props.data.notesByRelation?.data?.map((data) => data.attributes);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setNotes(fetchedNotes as NoteFieldsFragment[]);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
			setNotes([]);
		} finally {
			sethasFetchedNotes(true);
		}
	};

	useEffect(() => {
		if (
			session.status === 'authenticated' &&
			session.data.accessToken &&
			contentType &&
			contentTypeId &&
			notes.length === 0 &&
			hasFetchedNotes === false
		) {
			getNotes();
		}
	}, [session, pathname, contentType, contentTypeId, notes]);

	useEffect(() => {
		if (
			session.status === 'authenticated' &&
			session.data.accessToken &&
			contentType &&
			contentTypeId &&
			!contactPerson &&
			hasFetchedContactPerson === false
		) {
			getContactPerson();
		}
	}, [session, pathname, contentType, contentTypeId, contactPerson]);

	useEffect(() => {
		setNotes([]);
		sethasFetchedNotes(false);
	}, [pathname]);

	const onSizeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		setSelectedSize(Number(event.target.value));
	};

	const combineAccordionItems = (): any => {
		const combinedAccordionItems: any[] = [];

		if (contactPerson && contactPerson.length > 0) {
			combinedAccordionItems.push({
				__typename: 'ComponentInternalAccordeonItem',
				itemTitle: intl.formatMessage({ id: 'modules.note.contactPerson' }),
				description: contactPerson
			});
		}

		notes?.map((note) => {
			combinedAccordionItems.push({
				__typename: 'ComponentInternalAccordeonItem',
				itemTitle: note?.title || '',
				description: note?.note || ''
			});
		});

		return combinedAccordionItems;
	};

	return (
		<>
			{contactPerson || notes.length > 0 ? (
				<div className="container">
					<div className={styles.noteContainer}>
						<div className={styles.header}>
							<Button
								variant="tertiary-light"
								icon={<Icon customIcon={toggleIcon} size="14px" />}
								iconPosition="left"
								title={`${intl.formatMessage({ id: 'components.notes.title' })} (${
									contactPerson ? notes.length + 1 : notes.length
								})`}
								onClick={toggleIsOpen}
							/>
							{isOpen && (
								<div className={styles.sizeSelect}>
									<Select
										className={styles.dropdown}
										options={sizeOptions}
										value={selectedSize}
										onChange={onSizeChange}
									/>
								</div>
							)}
						</div>
						{isOpen && (
							<div className={styles.content} style={{ height: sizeOptions[selectedSize].height }}>
								<Accordeon
									variant="note"
									module={{
										__typename: 'ComponentModulesAccordeon',
										sections: [
											{
												__typename: 'ComponentInternalAccordeonSection',
												items: combineAccordionItems()
											}
										],
										defaultModuleOptions: {
											padding: 'none',
											backgroundColor: 'grey'
										}
									}}
								/>
							</div>
						)}
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
