import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { Button } from 'components/button';
import { Icon } from 'components/icon/icon';
import { Link } from 'components/link/link';
import { ILink } from 'api/types';
import IcnArrowRight from 'public/assets/icons/arrow-right.svg';
import IcnArrowLeft from 'public/assets/icons/arrow-left.svg';

import styles from './menu-items.module.scss';

export interface IMenuItem {
	title?: ILink;
	links: (IMenuItem | ILink)[];
}

export interface IMenuItemsProps {
	items: (IMenuItem | ILink)[];
	goBack: () => void;
}

const MenuItems = (props: IMenuItemsProps): React.JSX.Element => {
	const menuItem = props.items[0] as IMenuItem;
	const isSingleGroup = props.items.length === 1 && !!menuItem.links;
	const [subMenuItems, setSubMenuItems] = useState<(IMenuItem | ILink)[] | undefined>();
	const [subMenuTitle, setSubMenuTitle] = useState<ILink | undefined>();

	const goBack = (): void => {
		if (isSingleGroup) {
			props.goBack();
			setSubMenuItems(undefined);
		} else if (subMenuItems) {
			setSubMenuItems(undefined);
		} else {
			props.goBack();
		}
	};

	useEffect(() => {
		if (isSingleGroup) {
			setSubMenuItems(menuItem.links);
			setSubMenuTitle(menuItem.title);
		}

		return () => {
			setSubMenuItems(undefined);
		};
	}, [props.items]);

	return (
		<div>
			{!subMenuItems && !isSingleGroup ? (
				<div>{renderItems(props.items, setSubMenuItems, setSubMenuTitle, goBack)}</div>
			) : (
				<div>{!!subMenuItems && renderItems(subMenuItems, setSubMenuItems, setSubMenuTitle, goBack, subMenuTitle)}</div>
			)}
		</div>
	);
};

const renderItems = (
	items: (IMenuItem | ILink)[],
	setSubMenuLinks: (subItems: (IMenuItem | ILink)[]) => void,
	setSubMenuTitle: (title?: ILink) => void,
	goBack: () => void,
	title?: ILink
): React.JSX.Element => {
	return (
		<div className={styles.items}>
			<Button
				icon={<Icon customIcon={<IcnArrowLeft />} />}
				iconPosition="left"
				fullWidth={false}
				title="Terug"
				variant="tertiary-dark"
				onClick={() => {
					goBack();
				}}
			/>
			{title && (
				<div className={clsx(styles.item, styles.title)}>
					<Link variant="tertiary-dark" href={title.href}>
						{title.name}
					</Link>
				</div>
			)}
			{items.map((item, index) => {
				if (isLink(item)) {
					const link = item as ILink;
					return (
						<div className={styles.item} key={index}>
							<Link variant="tertiary-dark" href={link.href}>
								{link.name}
							</Link>
						</div>
					);
				}
				const menuItem = item as IMenuItem;
				return (
					<div className={styles.item} key={index}>
						<Button
							icon={<Icon customIcon={<IcnArrowRight />} size="12px" />}
							iconPosition="right"
							fullWidth={false}
							title={menuItem.title?.name}
							variant="tertiary-dark"
							onClick={() => {
								setSubMenuLinks(menuItem.links);
								setSubMenuTitle(menuItem.title);
							}}
						/>
					</div>
				);
			})}
		</div>
	);
};

const isLink = (value: any): boolean => {
	return value.name !== undefined;
};

export { MenuItems };
