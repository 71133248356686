import clsx from 'clsx';
import React, { useRef, useState, useEffect } from 'react';
import FocusLock from 'react-focus-lock';
import { useRouter } from 'next/router';
import { Icon } from 'components/icon/icon';
import IcnHamburger from 'public/assets/icons/hamburger.svg';
import IcnCross from 'public/assets/icons/cross.svg';
import { LanguageSwitcher } from 'components/language-switcher/language-switcher';
import { Link } from 'components/link/link';
import { Button } from 'components/button';
import IcnArrowRight from 'public/assets/icons/arrow-right.svg';
import { Maybe, NavbarFieldsFragment } from 'api/graphql-types';

import { IMenuItem, MenuItems } from './menu-items';
import styles from './mobile-menu.module.scss';
import { useIntl } from 'react-intl';

export interface IMobileMenuProps {
	navbar: NavbarFieldsFragment;
	hideLanguageSwitcher?: boolean;
}

const MobileMenu = (props: IMobileMenuProps): React.JSX.Element => {
	const router = useRouter();
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectedSubMenu, setSelectedSubMenu] = useState<'popular' | 'themes' | undefined>(undefined);

	const menuRef = useRef<HTMLDivElement>(null);

	const goBack = (): void => {
		setSelectedSubMenu(undefined);
	};

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'visible';
		}
	}, [isOpen]);

	useEffect(() => {
		setIsOpen(false);
	}, [router]);

	return (
		<FocusLock disabled={!isOpen}>
			<div className={styles.mobileMenu}>
				{/* Button */}
				<button
					type="button"
					aria-expanded={isOpen}
					className={styles.menuButton}
					onClick={() => {
						setIsOpen(!isOpen);
					}}
					aria-label={
						isOpen ? formatMessage({ id: 'modules.navabar.close' }) : formatMessage({ id: 'modules.navabar.open' })
					}
				>
					<span className={styles.title}>{formatMessage({ id: 'modules.navabar.title' })}</span>
					<span className={styles.icon}>
						{isOpen ? (
							<Icon customIcon={<IcnCross />} size="14px" />
						) : (
							<Icon customIcon={<IcnHamburger />} size="14px" />
						)}
					</span>
				</button>
				{/* Mobile menu */}
				<nav
					aria-labelledby="mobile-navigation"
					ref={menuRef}
					className={clsx(styles.menu, isOpen ? styles.isOpen : '')}
				>
					{!selectedSubMenu && (
						// Default menu
						<div className={styles.content}>
							<div className={styles.items}>
								<span className={styles.item}>
									<Button
										icon={<Icon customIcon={<IcnArrowRight />} />}
										iconPosition="right"
										fullWidth={false}
										title={props?.navbar?.mostPopularTitle}
										variant="tertiary-dark"
										onClick={() => {
											setSelectedSubMenu('popular');
										}}
									/>
								</span>
								<span className={styles.item}>
									<Button
										icon={<Icon customIcon={<IcnArrowRight />} />}
										iconPosition="right"
										fullWidth={false}
										title={props?.navbar?.ThemeGroupTitle}
										variant="tertiary-dark"
										onClick={() => {
											setSelectedSubMenu('themes');
										}}
									/>
								</span>

								{props?.navbar?.bottomLinks?.map((item, index) => (
									<span className={styles.item} key={index}>
										<Link href={item?.href?.url} variant="tertiary-dark">
											{item?.href?.text}
										</Link>
									</span>
								))}
							</div>
							<div className={styles.bottomItems}>
								{props.navbar?.rightNavLink && (
									<Link variant="tertiary-dark" href={props.navbar.rightNavLink?.url}>
										{props.navbar.rightNavLink?.text}
									</Link>
								)}
								{!props.hideLanguageSwitcher && (
									<div className={styles.languageSwitcher}>
										<LanguageSwitcher variant="dark" />
									</div>
								)}
							</div>
						</div>
					)}
					{!!selectedSubMenu && (
						<div className={styles.content}>
							<MenuItems
								items={getMenuItems(
									selectedSubMenu === 'popular' ? props?.navbar?.mostPopularGroupLinks : props?.navbar?.themeGroupLinks
								)}
								goBack={goBack}
							/>
						</div>
					)}
				</nav>
			</div>
		</FocusLock>
	);
};

const getMenuItems = (
	linkMenuItems?: Maybe<
		Array<
			Maybe<{
				__typename?: 'ComponentComponentsLinkGroup';
				title: string;
				links?: Maybe<Array<Maybe<{ __typename?: 'ComponentComponentsLink'; title: string; href: any }>>>;
			}>
		>
	>
): IMenuItem[] => {
	const result = linkMenuItems?.map((linkItem) => {
		const menuItem: IMenuItem = {
			title: { name: linkItem?.title || '', href: '/' } || '',
			links:
				linkItem?.links?.map((link) => ({
					name: link?.href?.text || '',
					href: link?.href?.url || ''
				})) || []
		};

		return menuItem;
	});

	return result || [];
};

export { MobileMenu };
