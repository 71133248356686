import Image, { IStrapiImageAttributes } from 'components/image';

export interface IAccessibilityLogoProps {
	attributes?: IStrapiImageAttributes;
	isSmall?: boolean;
}

export const AccessibilityLogo = ({ attributes, isSmall }: IAccessibilityLogoProps): React.JSX.Element =>
	attributes ? (
		<Image width={isSmall ? 35 : 61} height={isSmall ? 47 : 82} attributes={attributes} imageSize="thumbnail" />
	) : (
		<></>
	);

export default AccessibilityLogo;
