import { useEffect, useState, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import useScrollPosition from '@react-hook/window-scroll';

import { NavbarFieldsFragment } from 'api/graphql-types';
import { useScrollDirection } from 'utils/hooks/use-scroll-direction';
import { getSite } from 'utils/subsite';
import { LanguageSwitcher } from 'components/language-switcher/language-switcher';
import { TextField } from 'components/text-field/text-field';
import { Icon } from 'components/icon/icon';
import { Dropdown } from 'components/dropdown/dropdown';
import { Link } from 'components/link/link';
import { NextLink } from 'components/next-link';
import { MobileMenu } from 'components/mobile-menu/mobile-menu';
import Image from 'components/image';

import IcnSearch from 'public/assets/icons/search.svg';

import styles from './navbar.module.scss';

const NotificationBar = dynamic(() => import('modules/notification-bar'), {
	ssr: true
});

const ScreenReader = dynamic(() => import('components/screen-reader/screen-reader'), {
	ssr: false
});

export interface INavbarProps {
	navbar: NavbarFieldsFragment;
	hideSearchbar?: boolean;
	hideLanguageSwitcher?: boolean;
	hideDropdowns?: boolean;
	customSearch?: (search: string) => void;
}

const Navbar = (props: INavbarProps): React.JSX.Element => {
	const intl = useIntl();
	const router = useRouter();
	const [search, setSearch] = useState<string>('');
	const [popularIsOpen, setPopularIsOpen] = useState<boolean>(false);
	const [themeIsOpen, setThemeIsOpen] = useState<boolean>(false);
	const [imageAltSiteLogo, setImageAltSiteLogo] = useState<string>();

	const [y, setY] = useState(typeof window === 'undefined' ? 0 : window.scrollY);
	const [hideNavbar, setHideNavbar] = useState<boolean>(false);

	const scrollDirection = useScrollDirection();
	const scrollY = useScrollPosition();
	const stickyNavRef = useRef<HTMLDivElement>(null);
	const placeholder = intl.formatMessage({ id: 'global.search' });

	useEffect(() => {
		let host = window.location.host;

		if (host.includes('localhost')) host = 'gelderland';

		const site = getSite(host);

		setImageAltSiteLogo(intl.formatMessage({ id: `global.logo.${site}` }));
	}, []);

	useEffect(() => {
		const scrollingDown = scrollDirection < 0;
		const navHeight = stickyNavRef.current?.getBoundingClientRect().height;

		if (scrollingDown && scrollY > (navHeight || 120)) {
			setHideNavbar(true);
		} else {
			setHideNavbar(false);
		}
	}, [scrollY, scrollDirection, stickyNavRef]);

	const handleNavigation = useCallback(
		(e: any) => {
			const window = e.currentTarget;
			if (y > window.scrollY) {
				setHideNavbar(false);
			} else if (y < window.scrollY && window.scrollY > 120) {
				setHideNavbar(true);
			}
			setY(window.scrollY);
		},
		[y]
	);

	useEffect(() => {
		setY(window.scrollY);
		window.addEventListener('scroll', handleNavigation);

		return () => {
			window.removeEventListener('scroll', handleNavigation);
		};
	}, [handleNavigation]);

	const onSearch = async (): Promise<void> => {
		if (props.customSearch) {
			props.customSearch(search);
		} else {
			router.push({ pathname: '/zoeken', query: { zoekterm: search } });
		}
	};

	return (
		<header className={styles.Navbar}>
			<div className={clsx(styles.fixed, hideNavbar && styles.hideSticky)} ref={stickyNavRef}>
				<div className={styles.topBar}>
					<div className={styles.content}>
						<div className={styles.left}>
							<span>
								<NextLink href="/">
									<Image
										attributes={props.navbar?.logo?.data?.attributes}
										imageSize="small"
										fallbackAlt={imageAltSiteLogo}
										width={224}
										height={20}
										priority
									/>
								</NextLink>
							</span>
						</div>
						<div className={styles.middle}>
							{!props.hideSearchbar && (
								<form
									onSubmit={(event: any) => {
										event.preventDefault();
										onSearch();
									}}
								>
									<label className={styles.searchLabel} htmlFor="search">
										{intl.formatMessage({ id: 'global.search' })}
									</label>

									<div className={styles.searchBar}>
										<TextField
											onChange={(e) => {
												setSearch(e.target.value);
											}}
											value={search}
											type="search"
											id="search"
											placeholder={props.navbar?.searchPlaceholderText || placeholder}
										/>
										<button type="submit" className={styles.searchBarIconDesktop} aria-label={placeholder}>
											<Icon
												customIcon={
													<span className={styles.searchIcon}>
														<IcnSearch />
													</span>
												}
												size="18px"
												forceColor="green"
											/>
										</button>
									</div>
								</form>
							)}
						</div>
						<div className={styles.right}>
							{props.navbar?.rightNavLink && (
								<span className={clsx(styles.label, styles.contact)}>
									<Link variant="tertiary-light" href={props?.navbar?.rightNavLink?.url}>
										{props.navbar?.rightNavLink?.text}
									</Link>
								</span>
							)}

							{!props.hideLanguageSwitcher && (
								<span id="language-switcher" className={clsx(styles.label, styles.language)}>
									<LanguageSwitcher variant="light" />
								</span>
							)}
							<span className={styles.screenReader}>
								<ScreenReader />
							</span>
							<MobileMenu navbar={props.navbar} hideLanguageSwitcher={props.hideLanguageSwitcher} />
						</div>
					</div>
				</div>
				{(!!props.navbar?.mostPopularTitle || !!props.navbar?.ThemeGroupTitle || !!props.navbar?.bottomLinks) && (
					<div className={styles.bottomBar}>
						<div className={styles.content}>
							{!props.hideDropdowns && (
								<div className={styles.left}>
									{props.navbar?.mostPopularTitle && (
										<span className={styles.navBarItem}>
											<Dropdown
												label={props.navbar?.mostPopularTitle || ''}
												onClick={() => setPopularIsOpen(!popularIsOpen)}
												linkGroups={
													props.navbar?.mostPopularGroupLinks?.map((item) => ({
														links:
															item?.links?.map((link) => ({
																href: link?.href?.url || '',
																title: link?.href?.text || ''
															})) || [],
														title: item?.title || ''
													})) || []
												}
											/>
										</span>
									)}
									{props.navbar?.ThemeGroupTitle && (
										<span className={styles.navBarItem}>
											<Dropdown
												label={props.navbar?.ThemeGroupTitle || ''}
												onClick={() => setThemeIsOpen(!themeIsOpen)}
												linkGroups={
													props.navbar?.themeGroupLinks?.map((item) => ({
														links:
															item?.links?.map((link) => ({
																href: link?.href?.url || '',
																title: link?.href?.text || ''
															})) || [],
														title: item?.href?.text || '',
														href: item?.href?.url || undefined
													})) || []
												}
											/>
										</span>
									)}
								</div>
							)}
							{props.navbar?.bottomLinks && (
								<nav
									className={clsx(styles.right, !props.hideDropdowns && styles.end)}
									aria-label={intl.formatMessage({ id: 'modules.navabar.subnavigation' })}
								>
									{props.navbar?.bottomLinks?.map((link, index) => (
										<span key={index}>
											<Link variant="tertiary-dark" href={link?.href?.url}>
												{link?.href?.text}
											</Link>
										</span>
									))}
								</nav>
							)}
						</div>
					</div>
				)}

				{!props.hideSearchbar && (
					<div className={styles.mobileSearch}>
						<form
							onSubmit={(event: any) => {
								event.preventDefault();
								onSearch();
							}}
						>
							<div className={styles.searchBar}>
								<TextField
									onChange={(e) => {
										setSearch(e.target.value);
									}}
									value={search}
									type="text"
									id="mobileSearch"
									placeholder={props.navbar?.searchPlaceholderText || placeholder}
								/>
								<button
									type="submit"
									onClick={() => onSearch()}
									tabIndex={0}
									className={styles.searchBarIconMobile}
									aria-label={placeholder}
								>
									<Icon customIcon={<IcnSearch />} size="18px" />
								</button>
							</div>
						</form>
					</div>
				)}
			</div>

			<NotificationBar />
		</header>
	);
};
export { Navbar };
