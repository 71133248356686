import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import dynamic from 'next/dynamic';
import { useMutation } from '@apollo/client';

import client from 'api/apollo-client';
import { AddCommentDocument } from 'api/graphql-types';
import { getServerPageGetComments } from 'api/graphql-helper';
import { Typography } from 'components/typography';
import { Pagination } from 'components/pagination';
import { usePrevious } from 'utils/use-previous';

import { Comment, IComment } from './comment';
import { ICommentMVO } from './add-comment-form';
import styles from './comments.module.scss';

const AddCommentForm = dynamic(() => import('./add-comment-form'), { ssr: false });

export interface IComments {
	id: string;
	typeName: string;
}

const typeNameToUid = (type: string): string => {
	const split = type.split(/(?=[A-Z])/);
	const name = split.join('-').toLowerCase();
	return `api::${name}.${name}`;
};

export const Comments = ({ id, typeName }: IComments): React.JSX.Element => {
	const intl = useIntl();
	const limit = 4;

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [total, setTotal] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isEnabled, setIsEnabled] = useState<boolean>(false);
	const [messages, setMessages] = useState<IComment[]>([]);
	const [addComment, { data, loading, called }] = useMutation(AddCommentDocument, { client });

	const prevCurrentPage = usePrevious(currentPage);

	const handlePageChange = (page: number): void => {
		if (page !== currentPage) {
			setCurrentPage(page);
		}
	};

	const getComments = useCallback(async (): Promise<void> => {
		setIsLoading(true);

		try {
			const commentsResult = await getServerPageGetComments(
				{
					variables: {
						id: Number(id),
						uid: typeNameToUid(typeName),
						page: currentPage,
						pageSize: limit
					}
				},
				client
			);

			const conversationsEnabled = commentsResult.props.data.getCommentsEnabled;
			const conversationItems = commentsResult.props.data.conversationsConversationitems?.data.map((x) => x.attributes);
			const conversationItemsCount = commentsResult.props.data.conversationsConversationitems?.meta.pagination.total;

			setTotal(conversationItemsCount || 0);
			setIsEnabled(conversationsEnabled || false);
			setMessages((conversationItems as IComment[]) || []);
		} catch (error: any) {
			// eslint-disable-next-line no-console
			console.error('Cannot get comments', error);
		}

		setIsLoading(false);
	}, [id, typeName, currentPage]);

	const onSubmit = async ({ author, message }: ICommentMVO): Promise<void> => {
		if (!author || !message) return;
		setIsLoading(true);
		try {
			await addComment({
				client,
				variables: {
					contentTypeUid: typeNameToUid(typeName),
					contentTypeId: Number(id),
					author,
					message
				},
				optimisticResponse: {
					addComment: {
						data: {
							attributes: {
								contentTypeUid: typeNameToUid(typeName),
								contentTypeId: Number(id),
								author,
								message
							}
						}
					}
				}
			});
		} catch (error_) {
			// eslint-disable-next-line no-console
			console.error(error_);
		} finally {
			setIsLoading(false);
			await getComments();
		}
	};

	useEffect(() => {
		if (id && typeName) {
			getComments();
		}

		return () => {
			setMessages([]);
			setIsEnabled(false);
		};
	}, [id, typeName]);

	useEffect(() => {
		if (id && typeName && prevCurrentPage && prevCurrentPage !== currentPage) {
			getComments();
		}
	}, [currentPage]);

	if (!isEnabled) return <></>;

	// Section ID will be used as scroll anchor point for customer
	return (
		<section id="praat-mee" className={styles.section}>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<Typography tag="h2" variant="h5">
						{called
							? intl.formatMessage({ id: 'modules.comments.success.title' })
							: intl.formatMessage({ id: 'modules.comments.title' })}
					</Typography>

					{called ? (
						<>
							<div className={styles.success}>{intl.formatMessage({ id: 'modules.comments.success.message' })}</div>
							{data?.addComment?.data?.attributes && <Comment {...data.addComment.data.attributes} preview />}
						</>
					) : (
						<AddCommentForm
							intl={intl}
							onSubmit={(formData) => {
								onSubmit(formData);
							}}
							isLoading={isLoading || loading}
						/>
					)}

					{messages.length > 0 &&
						messages.map((messageProps, index) => <Comment key={`comment-${index}`} {...messageProps} />)}

					<Pagination currentPage={currentPage} totalPages={Math.ceil(total / limit)} onPageChange={handlePageChange} />
				</div>
			</div>
		</section>
	);
};

export default Comments;
