import { FooterFieldsFragment } from 'api/graphql-types';
import { NextLink } from 'components/next-link';
import { Typography } from 'components/typography';
import Image from 'components/image';

import AccessibilityLogo from './accessibility-logo';
import styles from './footer.module.scss';

export interface IFooterProps {
	footer: FooterFieldsFragment;
}

export const Footer = (props: IFooterProps): React.JSX.Element => {
	return (
		<div id="footer" className={styles.Footer}>
			<div className={styles.container}>
				<nav aria-labelledby="footer-primary-navigation" className={styles.columns}>
					{props.footer?.themeLinkGroup && (
						<div className={styles.row}>
							<Typography tag="h2" variant="h5">
								{props.footer?.themeLinkGroup?.title}
							</Typography>
							<ul>
								{props.footer?.themeLinkGroup?.links?.map((link) => (
									<li key={link?.title}>
										<NextLink href={link?.href?.url}>{link?.href?.text}</NextLink>
									</li>
								))}
							</ul>
						</div>
					)}
					{props.footer?.contactLinkGroup && (
						<div className={styles.row}>
							<Typography tag="h2" variant="h5">
								{props.footer?.contactLinkGroup?.title}
							</Typography>
							<ul>
								{props.footer?.contactLinkGroup?.links?.map((link) => (
									<li key={link?.title}>
										<NextLink href={link?.href?.url}>{link?.href?.text}</NextLink>
									</li>
								))}
							</ul>
						</div>
					)}
					{props.footer?.socialLinkGroup && (
						<div className={styles.row}>
							<Typography tag="h2" variant="h5">
								{props.footer?.socialLinkGroup?.title}
							</Typography>
							<ul>
								{props.footer?.socialLinkGroup?.links?.map((link) => (
									<li key={link?.title} className={styles.iconLink}>
										<div className={styles.icon}>
											<Image attributes={link?.icon?.data?.attributes} imageSize="small" fill />
										</div>
										<NextLink href={link?.href?.url}>{link?.href?.text}</NextLink>
									</li>
								))}
							</ul>
						</div>
					)}
				</nav>

				<nav aria-labelledby="footer-secondary-navigation" className={styles.bottomLinks}>
					<ul>
						{props.footer?.accessibilityLogo && (
							<li className={styles.accesibilityLogoDesktop}>
								{props.footer?.accessibilityLink ? (
									<NextLink href={props.footer.accessibilityLink}>
										<AccessibilityLogo attributes={props.footer?.accessibilityLogo?.data?.attributes} isSmall />
									</NextLink>
								) : (
									<AccessibilityLogo attributes={props.footer?.accessibilityLogo?.data?.attributes} isSmall />
								)}
							</li>
						)}
						{props.footer?.bottomLinks?.map((link) => (
							<li key={link?.title}>
								<NextLink href={link?.href?.url}>{link?.href?.text}</NextLink>
							</li>
						))}
					</ul>
				</nav>
			</div>

			{props.footer?.logo && (
				<div className={styles.logo}>
					<Image
						attributes={props.footer?.logo?.data?.attributes}
						imageSize="small"
						width={251}
						height={90}
						style={{ objectFit: 'contain', objectPosition: 'top right' }}
					/>
				</div>
			)}
			{props.footer?.accessibilityLogo && (
				<div className={styles.accesibilityLogoMobile}>
					{props.footer?.accessibilityLink ? (
						<NextLink href={props.footer.accessibilityLink}>
							<AccessibilityLogo attributes={props.footer?.accessibilityLogo?.data?.attributes} />
						</NextLink>
					) : (
						<AccessibilityLogo attributes={props.footer?.accessibilityLogo?.data?.attributes} />
					)}
				</div>
			)}
		</div>
	);
};
