import { ConversationsConversationitem, Scalars, Maybe } from 'api/graphql-types';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { getFormattedDate } from 'utils/date';
import { stringToEmoji } from 'utils/convert-emoji';
import { Typography } from 'components/typography';

import styles from './comments.module.scss';

export interface IComment extends Omit<ConversationsConversationitem, 'published_at' | 'created_at' | 'updated_at'> {
	publishedAt?: Maybe<Scalars['DateTime']>;
	preview?: boolean;
}

export const Comment = ({ author, message, publishedAt, preview }: IComment): React.JSX.Element => {
	const intl = useIntl();
	const router = useRouter();
	const { locale } = router;

	return (
		<div className={clsx(preview && styles.preview)}>
			<div className={styles.head}>
				<Typography tag="span" variant="h5">
					{author}
				</Typography>
				<span className={styles.date}>{getFormattedDate(new Date(publishedAt), 'dd MMMM yyyy', locale)}</span>
				{preview && <span className={styles.date}>{intl.formatMessage({ id: 'modules.comments.success.label' })}</span>}
			</div>

			<div className={styles.message}>{stringToEmoji(message || '')}</div>
		</div>
	);
};

export default Comment;
