import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import { Icon } from 'components/icon/icon';
import { Button } from 'components/button';
import { NextLink } from 'components/next-link';
import { Link } from 'components/link/link';

import IcnCarrotDown from 'public/assets/icons/carot-down.svg';
import styles from './dropdown.module.scss';
import { toKebabCase } from 'utils/kebab-case';

export interface IDropdownProps {
	label: string;
	onClick: () => void;
	linkGroups: {
		title: string;
		links: { title: string; href: string }[];
		href?: string;
	}[];
}

const Dropdown = (props: IDropdownProps): React.JSX.Element => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const intl = useIntl();
	const router = useRouter();

	const handleFocus = (): void => {
		setIsOpen(true);
	};

	const handleMouseBlur = (): void => {
		setIsOpen(false);
	};

	const handleMouseEnter = (): void => {
		setIsOpen(true);
	};

	const handleMouseLeave = (): void => {
		setIsOpen(false);
	};

	const handleClick = (): void => {
		setIsOpen(!isOpen);
	};

	// Close dropdown on excape (WCAG)
	const handleKeyDown = (event: KeyboardEvent): void => {
		if (event.key === 'Escape') {
			setIsOpen(false);
		}
	};

	const onRouteChangeStart = React.useCallback(() => {
		setIsOpen(false);
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);
		router.events.on('routeChangeStart', onRouteChangeStart);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	useEffect(() => {
		setIsOpen(false);
	}, [router]);

	return (
		<div
			className={styles.Dropdown}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onFocus={handleFocus}
			onBlur={handleMouseBlur}
		>
			<div className={styles.button} onBlur={handleMouseLeave}>
				<Button
					variant="tertiary-dark"
					title={props.label}
					icon={<Icon customIcon={<IcnCarrotDown />} size="14px" />}
					iconPosition="right"
					onClick={handleClick}
				/>
			</div>
			<div
				className={clsx(styles.collapsibleMenu, isOpen ? styles.isOpen : '')}
				aria-label={`${
					isOpen
						? intl.formatMessage({ id: 'subsidy.status.open' })
						: intl.formatMessage({ id: 'subsidy.status.closed' })
				}`}
				onFocus={handleMouseEnter}
				onBlur={handleMouseLeave}
			>
				<div className={styles.content}>
					{props.linkGroups.map((item, index) => {
						const id = toKebabCase(item.title);

						return (
							<nav aria-labelledby={id} className={clsx(styles.contentGroup, item?.href && styles.hasLink)} key={index}>
								<h2>
									<NextLink id={id} href={item?.href}>
										{item?.title}
									</NextLink>
								</h2>
								{item?.links?.map((link, idx) => (
									<div className={styles.link} key={idx}>
										<Link variant="tertiary-dark" href={link?.href}>
											{link?.title}
										</Link>
									</div>
								))}
							</nav>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export { Dropdown };
