import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';

import styles from './language-switcher.module.scss';

export interface ILanguageSwitcherProps {
	variant: 'dark' | 'light';
}

interface IFullLocaleArray {
	locale: string;
	fullLocale: string;
}

const LanguageSwitcher = (props: ILanguageSwitcherProps): React.JSX.Element => {
	const router = useRouter();
	const intl = useIntl();
	const { locales } = router;
	const [cookie, setCookie] = useCookies(['NEXT_LOCALE']);
	const [availableLocales, setFullLocales] = useState<IFullLocaleArray[]>();

	React.useEffect(() => {
		const tempFullLocales: IFullLocaleArray[] = [];
		locales?.map((locale) => {
			switch (locale) {
				case 'nl':
					tempFullLocales.push({ locale: 'nl', fullLocale: intl.formatMessage({ id: 'global.dutch' }) });
					break;

				case 'en':
					tempFullLocales.push({ locale: 'en', fullLocale: intl.formatMessage({ id: 'global.english' }) });
					break;

				case 'de':
					tempFullLocales.push({ locale: 'de', fullLocale: intl.formatMessage({ id: 'global.german' }) });
					break;

				default:
					break;
			}
		});

		setFullLocales(tempFullLocales);
	}, [locales]);

	const onClick = (locale: string): void => {
		if (cookie.NEXT_LOCALE !== locale) {
			setCookie('NEXT_LOCALE', locale, {
				path: '/'
			});
		}
	};

	return (
		<div className={clsx(styles.LanguageSwitcher, props.variant === 'light' ? styles.light : styles.dark)}>
			{availableLocales &&
				availableLocales.length > 0 &&
				availableLocales.map((item, index) => (
					<span key={item.locale}>
						<a
							onClick={() => onClick(item.locale)}
							className={styles.link}
							href={item.locale === 'nl' ? '/' : `/${item.locale}`}
							aria-label={item.fullLocale}
						>
							{item.locale.toLocaleUpperCase()}
						</a>
						{index + 1 !== availableLocales.length && ' | '}
					</span>
				))}
		</div>
	);
};

export { LanguageSwitcher };
