import { SUBSITES } from 'constants/subsites';

export const getSite = (host: string) => {
	if (host?.includes('regioexpres')) {
		return SUBSITES.REGIO_EXPRES;
	}
	if (host?.includes(SUBSITES.OP_OOST)) {
		return SUBSITES.OP_OOST;
	}
	if (host?.includes(SUBSITES.EFRO_OOST)) {
		return SUBSITES.EFRO_OOST;
	}
	if (host?.includes(SUBSITES.CONDOLEANCE_REGISTER_PROVINCIE_GELDERLAND)) {
		return SUBSITES.CONDOLEANCE_REGISTER_PROVINCIE_GELDERLAND;
	}
	if (host?.includes(SUBSITES.GEO_LINK)) {
		return SUBSITES.GEO_LINK;
	}
	if (host?.includes('gelderland')) {
		return 'gelderland';
	}
};
