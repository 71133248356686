import { useRef, useEffect } from 'react';

// Hook
export const usePrevious = <T>(value: T): T => {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref: any = useRef<T>(null);

	// Store current value in ref
	useEffect(() => {
		if (value !== undefined && value !== null) {
			ref.current = value;
		}
	}, [value]); // Only re-run if value changes

	// Return previous value (happens before update in useEffect above)
	return ref.current;
};
