import { useSession } from 'next-auth/react';
import Script from 'next/script';

import { useAppSelector } from 'redux/store';
import { FooterFieldsFragment, NavbarFieldsFragment } from 'api/graphql-types';
import { Navbar } from 'modules/navbar/navbar';
import { Footer } from 'modules/footer/footer';
import { PreviewMode } from 'modules/preview-mode/preview-mode';
import { Note } from 'modules/note/note';
import { SkipLinks } from 'components/skip-links';
import Comments from 'modules/comments/comments';

import styles from './layout.module.scss';

interface ILayoutProps {
	navbar?: NavbarFieldsFragment;
	footer?: FooterFieldsFragment;
	contentId?: string;
	typeName?: string;
	children: React.ReactNode;
	customSearch?: (search: string) => void;
}

const Layout = (props: ILayoutProps): React.JSX.Element => {
	const session = useSession();

	const {
		publicationState: isPreviewMode,
		hideLanguageSwitcher,
		hideSearchbar,
		hideDropdowns
	} = useAppSelector((state) => state.settings);

	return (
		<>
			{typeof window !== 'undefined' &&
				window.location.host.includes('strapi.acc') &&
				process.env.NEXT_PUBLIC_USERBACK_API_KEY && (
					<Script id="userback" strategy="afterInteractive">
						{`window.Userback = window.Userback || {};
					Userback.access_token = '${process.env.NEXT_PUBLIC_USERBACK_API_KEY}';
					(function(d) {
						var s = d.createElement('script');s.async = true;
						s.src = 'https://static.userback.io/widget/v1.js';
						(d.head || d.body).appendChild(s);
					})(document);`}
					</Script>
				)}
			<div className={styles.layout}>
				<SkipLinks />
				{props.navbar && (
					<Navbar
						navbar={props.navbar}
						hideSearchbar={hideSearchbar}
						hideLanguageSwitcher={hideLanguageSwitcher}
						hideDropdowns={hideDropdowns}
						customSearch={props.customSearch}
					/>
				)}
				<main className={styles.main} id="content">
					{props.children}
					{props.contentId && props.typeName && <Comments id={props.contentId} typeName={props.typeName} />}
				</main>
				{props.footer && <Footer footer={props.footer} />}
			</div>
			{isPreviewMode === 'PREVIEW' && <PreviewMode />}
			{session?.status === 'authenticated' && <Note />}
		</>
	);
};

export default Layout;
