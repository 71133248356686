import React from 'react';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import { Button } from 'components/button';

import styles from './preview-mode.module.scss';

export const PreviewMode = (): React.JSX.Element => {
	const intl = useIntl();
	const router = useRouter();

	const handleExitPreview = async (): Promise<void> => {
		// For some reason, just linking to the exit-preview API doesn't work, so we fetch it.
		const response = await fetch('/next/api/exit-preview');

		if (response.ok) {
			router.push('/');
		} else {
			// eslint-disable-next-line no-console
			console.error('Failed to exit preview mode');
		}
	};

	return (
		<div className="container">
			<div className={styles.previewMode}>
				<span>{intl.formatMessage({ id: 'modules.previewMode.title' })}</span>

				<Button
					className={styles.link}
					onClick={() => handleExitPreview()}
					title={intl.formatMessage({ id: 'modules.previewMode.exit' })}
					variant="tertiary-light"
				/>
			</div>
		</div>
	);
};
