export const emojiRegex =
	/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;

// Convert emoji to string with the following structure: [e-xxxxx]
export const emojiToString = (string: string): string => {
	return string.replace(emojiRegex, (emoji) => `[e-${emoji?.codePointAt(0)?.toString(16)}]`);
};

// Convert string with [e-xxxxx] to string emoji
export const stringToEmoji = (string: string): string => {
	const stringWithEmoji = string.replace(/\[e-\w{4,6}\]/gu, (emoji) => {
		const code = emoji.match(/\w{4,6}/g);
		const unicode = code && code[0] && parseInt(code[0], 16);
		if (!unicode || typeof unicode !== 'number') return '';
		return String.fromCodePoint(unicode);
	});

	return stringWithEmoji;
};
