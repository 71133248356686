import { useIntl } from 'react-intl';
import styles from './skip-links.module.scss';

export const SkipLinks = (): React.JSX.Element => {
	const intl = useIntl();

	return (
		<div className={styles.container}>
			<div className={styles.wrapper} id="skiplinks">
				<a href="#content">{intl.formatMessage({ id: 'global.content' })}</a>
				<a href="#primary-navigation">{intl.formatMessage({ id: 'global.navigation' })}</a>
				<a href="#language-switcher">{intl.formatMessage({ id: 'global.languageSwitcher' })}</a>
				<a href="#search">{intl.formatMessage({ id: 'global.search' })}</a>
				<a href="#footer">{intl.formatMessage({ id: 'global.footer' })}</a>
			</div>
		</div>
	);
};

export default SkipLinks;
